
import { segmentEventTracking } from '@/utils/tracking'
import { Component, Vue } from 'vue-property-decorator'

@Component({})

export default class UserProfileVue extends Vue {
  private profile: any = this.$auth0.user
  private accessToken: string = this.$auth0.accessToken
  private tenants: any[] = this.$auth0.tenants
  private uid: number = this.$auth0.uid
  private idToken: string = this.$auth0.idTokenRaw
  private showTechnicalDetails: boolean = false

  private get technicalDetailsButtonLabel() {
    return this.showTechnicalDetails ? 'Hide Technical Details' : 'Show Technical Details'
  }

  private setShowTechnicalDetails(show: boolean) {
    this.showTechnicalDetails = show
    this.$gtm.trackEvent({ action: 'ShowTechnicalDetails', category: 'UserProfile', label: 'Toggle Show Technical Details', value: show })

    segmentEventTracking('UserProfile', {
      action: 'ShowTechnicalDetails',
      label: 'ToggleShowTechnicalDetails',
      value: show,
    })
  }
}
